<template>
<div ref="modalUpdateProfile" class="phone-modal phone-modal-port-search with-nav" style="bottom: -618px;" :class="{active: isUpdateProfileModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<LoadingIcon style="width: 27px; height: 27px;" class="icon register-icon" />
			<span>Profile Update successful</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="closeUpdateProfileModal" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->
	<div class="modal-message-block">
		<SuccessIcon class="success-icon" style="width: 90px; height: 90px;" />
		<h3>Done! Your profile has been updated.</h3>
	</div>
	<Button routeName="/my-profile" icon="ProfileIcon" class="btn btn-slate btn-tick btn-icon btn-icon-white btn-icon-small btn-icon-right" text="Return to My Profile"></Button>
	<Button routeName="/dashboard" icon="HomeIcon" class="btn btn-grey btn-tick btn-icon btn-icon-white btn-icon-small btn-icon-right" text="Return Home"></Button>
</div><!-- modal -->
</template>

<script>
import SuccessIcon from '@/assets/success-filled.svg';
import LoadingIcon from '@/assets/loading.svg';
import GreyCloseIcon from '@/assets/close-grey.svg';
import { required } from 'vuelidate/lib/validators';
import Button from '@/components/Button.vue';
export default {
name: 'UpdateProfileOverlay',
	props: {
		subtitle: String,
		title: String,
		icon: String
	},
	components: {
		GreyCloseIcon,
		SuccessIcon,
		LoadingIcon,
		Button
	},
	data () {
		return {
			form: {
				currentPassword: null,
				newPassword: null,
				newPasswordRepeat: null
			}
		};
	},
	validations () {
    	return {
    		form: {
            	currentPassword: {
					required
				},
				newPassword: {
					required
				},
				newPasswordRepeat: {
					required
				}
           	}
    	};
   },
    computed: {
        isUpdateProfileModalOpen () {
            return this.$store.state.isUpdateProfileModalOpen;
        }
    },
	methods: {
        closeUpdateProfileModal () {
        	this.$store.commit('UPDATE_PROFILE_TOGGLE');
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.success-icon {
		path {
			fill: $green_base;
		}
	}
	.text-link {
		color: $mid;
	}
	form {
		.field {
			margin-bottom: 25px;
		}
		.btn {
			margin-top: 35px;
		}
	}
	.section-title-wrap .section-title {
		color: $grey;
	}
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $green_dark;
				}
			}
			span {
				color: $green_dark;
			}
		}
	}
	.card-wrap {
		.card-block-purple {
			margin-bottom: 15px;
		}
	}
</style>
